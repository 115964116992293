import React from 'react'
import CardGridA from './CardGridA'

const CertDaysCards = ({data}) => {

    const sortAndFilter = (data) => {
        let today = new Date(Date.now())
        today.setHours(0,0,0,0)
        const sortedData = data.sort((a,b) => new Date (a.date) - new Date (b.date));
        const filterData = sortedData.filter(data => new Date(data.date) >= today);
        return filterData
    }

    const administrator = sortAndFilter(data.administrator)
    const advAdministrator = sortAndFilter(data.advanceAdministrator)
    const mcAdministrator = sortAndFilter(data.marketingcloudAdministrator)
    const mcEmailSpecialist = sortAndFilter(data.marketingCloudEmailSpecialist)
    const platformAppBuilder = sortAndFilter(data.platformAppBuilder)
    const developer = sortAndFilter(data.developer)
    const service = sortAndFilter(data.serviceCloudConsultant)
    const sales = sortAndFilter(data.salesCloudConsultant)

    return (
        <>
            {/* Administrator Section */}
            {administrator.length > 0 ? (
                <tm-section-a id="administrator" background="light" title="Preparing for your Administrator Certification">
                    <CardGridA dates={administrator}/> 
                </tm-section-a> 
            ) : (
                <tm-section-a id="administrator" background="light" title="Preparing for your Administrator Certification" description="Check back soon as we add more webinars" />
            )}
            
            {/* Advanced Administrator Section >> DISPLAY JUST WHEN HAVE ITEMS */}
            {advAdministrator && advAdministrator.length > 0 && (
                <tm-section-a background="white" title="Preparing for your Advanced Administrator Certification">
                    <CardGridA dates={advAdministrator}/> 
                </tm-section-a> 
            )}

            {/* Marketing Cloud Administrator Section */}
            {mcAdministrator.length > 0 ? (
                <tm-section-a background="light" title="Preparing for your Marketing Cloud Administrator Certification">
                    <CardGridA dates={mcAdministrator}/> 
                </tm-section-a> 
            ) : (
                <tm-section-a background="light" title="Preparing for your Marketing Cloud Administrator Certification" description="Check back soon as we add more webinars" />
            )}
                
            {/* Marketing Cloud Email Specialist Section */}
            {mcEmailSpecialist.length > 0 ? (
                <tm-section-a id="marketing" background="white" title="Preparing for your Marketing Cloud Email Specialist Certification">
                    <CardGridA dates={mcEmailSpecialist}/> 
                </tm-section-a> 
            ) : (
                <tm-section-a id="marketing" background="white" title="Preparing for your Marketing Cloud Email Specialist Certification" description="Check back soon as we add more webinars" />
            )}

            {/* Platform App Builder Section */}
            {platformAppBuilder.length > 0 ? (
                <tm-section-a id="developer" background="light" title="Preparing for your Platform App Builder Certification">
                    <CardGridA dates={platformAppBuilder}/> 
                </tm-section-a> 
            ) : (
                <tm-section-a id="developer" background="light" title="Preparing for your Platform App Builder Certification" description="Check back soon as we add more webinars" />
            )}
                
            {/* Developer Section */}
            {developer.length > 0 ? (
                <tm-section-a background="white" title="Preparing for your Platform Developer Certification">
                    <CardGridA dates={developer}/> 
                </tm-section-a> 
            ) : (
                <tm-section-a background="white" title="Preparing for your Platform Developer Certification" description="Check back soon as we add more webinars" />
            )}

            {/* Service Cloud Consultant Section >> DISPLAY JUST WHEN HAVE ITEMS */}
            {service && service.length > 0 && (
                <tm-section-a id="consultant" background="light" title="Preparing for your Service Cloud Consultant Certification">
                    <CardGridA dates={service}/> 
                </tm-section-a> 
            )}
            
            {/* Sales Cloud Consultant Section >> DISPLAY JUST WHEN HAVE ITEMS */}
            {sales && sales.length > 0 && (
                <tm-section-a background="white" title="Preparing for your Sales Cloud Consultant Certification">
                    <CardGridA dates={sales}/> 
                </tm-section-a> 
            )}
        </>
    )
}
  
export default CertDaysCards
