import React from 'react'

const CardGridA = ({dates}) => {

    const sizeClass = dates.length < 3 ? `${dates.length}-of-3` : '1-of-1'

    return (
        <>
            <div className="slds-grid slds-wrap slds-grid_align-center">
                <div className={`slds-size_${sizeClass}`}>
                    <tm-card-grid-a> 
                        {
                            dates.map((item, index) => {
                                return (
                                    <tm-grid-item
                                        key = {index}
                                        cta-href = {item.url}
                                        cta-target="_blank" 
                                        cta-title="Register" 
                                        description= {item.time}
                                        title= {item.date} />
                                )
                            })
                        }
                    </tm-card-grid-a>
                </div>
            </div>
        </>
    )
  }
  
  export default CardGridA
  