import React from 'react';
import Layout from '../layouts/Layout';
import { Helmet } from 'react-helmet';
import CertDaysCards from '../components/CertDaysCards';
import certDaysData from '../../data/certificationDays.json';

export const CertDaysTemplate = data => {
  const {
    pageContext: {
      lang,
      items,
      slug
    }
  } = data;

  const cardsData = certDaysData.length > 0 ? certDaysData[0] : [];

  let waintIntval1;
  let waintIntval2; 

  const intval = () => {
    waintIntval1 = setInterval(function() {addClickMethodToScrollOnHero()}, 500);
    waintIntval2 = setInterval(function() {addClickMethodToScrollOnCards()}, 500);
  };

  const addClickMethodToScrollOnHero = () => {
    const shadow = document.querySelectorAll('#hero')[0].shadowRoot;
    if(shadow) {
      clearInterval( waintIntval1);
      const link = shadow.querySelector('lwc-tds-button-link').shadowRoot.querySelector('a');
      link.addEventListener('click', scrollToElement);
    }
  }

  const addClickMethodToScrollOnCards = () => {
    const shadow = document.querySelectorAll('#upcoming_certification_days tm-card-grid-a')[0].shadowRoot;
    if(shadow) {
      clearInterval( waintIntval2);
      const cards = shadow.querySelectorAll('li');
      cards.forEach(card => {
        const link = card.querySelector('lwc-tm-card').shadowRoot.querySelector('a');
        link.addEventListener('click', scrollToElement);
      });
    }
  }

  const scrollToElement = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const hashParts = e.currentTarget.href.split('#');
    if(hashParts.length > 1) {
      const hashId = hashParts[1];
      const elements = document.querySelectorAll(`#${hashId}`);
      if(elements.length > 0) {
        elements[0].scrollIntoView({ behavior: 'smooth'});
      }
    }
  };

  return (
    <Layout lang={lang} slug={slug}>
      <Helmet>
        <meta name="title" content="Salesforce Certification Days"></meta>
        <meta name="og:title" content="Salesforce Certification Days"></meta>
        <meta name="description" content="Join a free, half-day webinar led by experts designed to help you prepare for your Salesforce Certification." />
        <meta name="og:description" content="Join a free, half-day webinar led by experts designed to help you prepare for your Salesforce Certification." />
        <meta name="keywords" content="Salesforce, Certification Days"/>
        <meta property="og:title" content="Salesforce Certification Days" />
        <title>Salesforce Certification Days</title>
      </Helmet>

      <tm-page class="theme--salesforce">

        <tm-hero-a id="hero" background="white" cta-href="/credentials/cert-days#upcoming_certification_days" cta-label="Register for Free" img-align="center" img-src="https://developer.salesforce.com/resources2/cert-days/cert-days-hero.jpeg" title="Salesforce Certification Days"></tm-hero-a> 
          
        <tm-section-a background="light"> 
          <tm-feature-grid-a> 
              <tm-grid-item description="Ready to grow your resume and highlight your skills? We’re here to help. These sessions are led by expert instructors who share their expertise, tips, and best practices for acing your Salesforce Certification exam." img-src="https://developer.salesforce.com/resources2/cert-days/cert_foliage.png" title="Join a free, half-day webinar to help you prepare for your Salesforce Certification" img-alt=""> </tm-grid-item> 
              <tm-grid-item description="There’s no better time to prove your hands-on experience with Salesforce and get a competitive edge that leads to new opportunities. BONUS: All Certification Days webinar attendees will receive a certification voucher for a 50% discount on the exam registration fee the webinar covered. Terms apply." img-src="https://developer.salesforce.com/resources2/cert-days/2.png" title="Earn a globally-recognized Salesforce Certification" img-alt=""> </tm-grid-item> 
          </tm-feature-grid-a> 
        </tm-section-a>

        <tm-section-a background="white" description="Select the session below that aligns to the certification exam you're working towards and the timezone that works best for you." id="upcoming_certification_days" title="Upcoming Certification Days"> 
          <tm-card-grid-a img-aspect="60%" img-type="full"> 
            <tm-grid-item cta-href="#administrator" cta-title="View" description="" img-alt="Administrator image" img-src="https://developer.salesforce.com/resources2/cert-days/admin.png" title="Administrator"> </tm-grid-item> 
            <tm-grid-item cta-href="#marketing" cta-title="View" description="" img-alt="Marketing image" img-src="https://developer.salesforce.com/resources2/cert-days/mark.png" title="Marketing"> </tm-grid-item> 
            <tm-grid-item cta-href="#developer" cta-title="View" description="" img-alt="Developer image" img-src="https://developer.salesforce.com/resources2/cert-days/dev.png" title="Developer"> </tm-grid-item> 
            {
              // <tm-grid-item cta-href="#consultant" cta-title="View" description="" img-alt="Consultant image" img-src="https://developer.salesforce.com/resources2/cert-days/cons.png" title="Consultant"> </tm-grid-item> 
            }
          </tm-card-grid-a> 
        </tm-section-a> 

        <CertDaysCards data={cardsData} />

        <tm-section-a background="light" description="Can't wait to get started? Here are some great Trailhead Trailmixes and Trails to get you ready:" title="Learning Trailmixes"> 
          <tm-card-grid-a img-aspect="60%" img-type="left">
            <tm-grid-item cta-href={`https://trailhead.salesforce.com/${lang}/content/learn/trails/administrator-certification-prep`} cta-target="_blank" cta-title="Start" img-src="https://developer.salesforce.com/resources2/cert-days/Trailhead-Logo.png" pretitle="Trailmix" title="Prepare for Your Salesforce Administrator Credential"  img-alt="Trailhead Logo"> </tm-grid-item> 
            <tm-grid-item cta-href={`https://trailhead.salesforce.com/${lang}/users/auser118794/trailmixes/prepare-for-your-marketing-cloud-administrator-certification-exa`} cta-target="_blank" cta-title="Start" img-src="https://developer.salesforce.com/resources2/cert-days/Trailhead-Logo.png" pretitle="Trailmix" title="Prepare for Your Marketing Cloud Administrator Certification Exam Prework"  img-alt="Trailhead Logo"> </tm-grid-item> 
            <tm-grid-item cta-href={`https://trailhead.salesforce.com/${lang}/users/strailhead/trailmixes/prepare-for-your-marketing-cloud-email-specialist-credential`} cta-target="_blank" cta-title="Start" img-src="https://developer.salesforce.com/resources2/cert-days/Trailhead-Logo.png" pretitle="Trailmix" title="Prepare for Your Salesforce Marketing Cloud Email Specialist Credential" img-alt="Trailhead Logo"> </tm-grid-item> 
            <tm-grid-item cta-href={`https://trailhead.salesforce.com/${lang}/users/00550000006yDdKAAU/trailmixes/prepare-for-your-salesforce-platform-app-builder-credential`} cta-target="_blank" cta-title="Start" img-src="https://developer.salesforce.com/resources2/cert-days/Trailhead-Logo.png" pretitle="Trailmix" title="Prepare for Your Salesforce Platform App Builder Credential" img-alt="Trailhead Logo"> </tm-grid-item> 
            <tm-grid-item cta-href={`https://trailhead.salesforce.com/${lang}/users/strailhead/trailmixes/prepare-for-your-salesforce-platform-developer-i-credential`} cta-target="_blank" cta-title="Start" img-src="https://developer.salesforce.com/resources2/cert-days/Trailhead-Logo.png" pretitle="Trailmix" title="Prepare for Your Salesforce Platform Developer I Credential" img-alt="Trailhead Logo"> </tm-grid-item> 
            <tm-grid-item cta-href={`https://trailhead.salesforce.com/${lang}/users/strailhead/trailmixes/prepare-for-your-salesforce-certified-associate-credential`} cta-target="_blank" cta-title="Start" img-src="https://developer.salesforce.com/resources2/certification-site/images/trailmix-logo.png" pretitle="Trailmix" title="Prepare for Your Salesforce Associate Credential" img-alt="Trailhead Logo"> </tm-grid-item> 
            <tm-grid-item cta-href={`https://trailhead.salesforce.com/${lang}/users/strailhead/trailmixes/prepare-for-your-salesforce-ai-associate-credential`} cta-target="_blank" cta-title="Start" img-src="https://developer.salesforce.com/resources2/certification-site/images/trailmix-logo.png" pretitle="Trailmix" title="Prepare for Your Salesforce AI Associate Credential" img-alt="Trailhead Logo"> </tm-grid-item> 
          </tm-card-grid-a> 
        </tm-section-a> 

        <tm-end-cap-a background="dark" img-alt="Certified Professional Logo" cta-href="https://trailhead.salesforce.com/credentials/administratoroverview" cta-label="Let's Go!" cta-target="_blank" img-src="https://developer.salesforce.com/resources2/cert-days/Badge.png" subtitle="Looking to learn more about certifications? Checkout our landing pages for more information" title="Learn More about Certifications"> </tm-end-cap-a>

        <tm-footnote tm-footnote_footnote-host="">
          <h3>
            Note
          </h3>

          <p>
            Sessions are not recorded and will not include a practice org, practice test, ebook, or any other additional materials.
          </p>

          <p>
            Sessions and the content presented may not be recorded or used for promotion.
          </p>

          <p>
            <a href="https://trailhead.salesforce.com/help?article=Certification-Days-FAQ" target="_blank">Check out our Certification Days FAQ</a>
          </p>
        </tm-footnote> 
      </tm-page> 
      
      <th-element-loader element="tm-page" style={{height: '100vh'}}></th-element-loader>
      {
        intval()
      }
    </Layout>
  );
};

export default CertDaysTemplate;
